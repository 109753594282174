import config from '../config';
import * as dateUtil from './date-util';

export const createPath = (...args: string[]) =>
  args.join('/').replace(/([^:]\/)\/+/g, '$1');

export const displayDay = (
  date: Date | string,
  format = config.dateFormats.weekDayMonthDay,
  literal = false
) => {
  let display;
  if (dateUtil.isToday(date)) {
    display = 'Today';
  } else if (dateUtil.isTomorrow(date)) {
    display = 'Tomorrow';
  } else if (dateUtil.isYesterday(date)) {
    display = 'Yesterday';
  } else {
    display = literal ? format : dateUtil.format(date, format);
  }

  return display;
};

export const pluralize = (text: string, count: number, override?: string) => {
  return count === 0 || count > 1 ? override || `${text}s` : text;
};

export const replace = (
  text: string,
  mapping: { [key: string]: string },
  flags: 'g' | 'gi' | 'i' = 'i'
) => {
  const regex = new RegExp(Object.keys(mapping).join('|'), flags);
  return text.replace(regex, function (matched) {
    return mapping[matched];
  });
};

export const replaceAll = (
  text: string,
  mapping: { [key: string]: string }
) => {
  return replace(text, mapping, 'gi');
};

export const replaceBr = (str: string) => str.replace(/<br \/>/, '\n');

export const titleCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .filter((part) => part.length > 0)
    .map((val) => `${val.charAt(0).toUpperCase()}${val.slice(1)}`)
    .join(' ');
};

export const sentenceCase = (str: string) => {
  const [first, ...rest] = str
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .filter((part) => part.length > 0);

  return [
    `${first.charAt(0).toUpperCase()}${first.slice(1)}`,
    ...rest.map((v) => v.toLocaleLowerCase()),
  ].join(' ');
};

export const formatExternalUrl = (url: string) => {
  if (!url.startsWith('http')) return `http://${url}`;
  return url;
};

export const snakeCase = (str: string) => {
  return str
    .split(' ')
    .reduce<string[]>((acc, val) => {
      return acc.concat(val.toLowerCase());
    }, [])
    .join('_');
};
