import config from '../../config';
import { getScript } from '../base';

export type GoogleApiLoadOptions = {
  callback?: (isSignedIn: boolean, error?: string) => void;
  docs?: string[];
  scopes?: string;
};

export const isSignedIn = () =>
  global.window?.gapi?.auth2?.getAuthInstance().isSignedIn.get();

// loads auth2 and API client libraries
export const load = async ({
  docs,
  scopes,
}: GoogleApiLoadOptions): Promise<boolean> => {
  return new Promise(async (resolve, reject): Promise<void> => {
    try {
      if (!window.gapi) {
        await getScript('//apis.google.com/js/api.js');
      }

      gapi.load('client:auth2', () => {
        if (gapi?.auth2.getAuthInstance()?.isSignedIn.get()) {
          logout();
        }

        if (!gapi.auth2.getAuthInstance()) {
          // Initializes the API client library
          gapi.client
            .init({
              apiKey: config.env.GOOGLE_API_KEY,
              clientId: config.env.GOOGLE_CLIENT_ID,
              discoveryDocs: docs,
              scope: scopes,
            })
            .then(() => {
              const option = new gapi.auth2.SigninOptionsBuilder();
              option.setPrompt('select_account');
              gapi.auth2
                .getAuthInstance()
                .signIn(option)
                .then((user) => {
                  resolve(user.isSignedIn());
                })
                .catch((data) => {
                  const error = JSON.parse(JSON.stringify(data));
                  console.error(error);
                  reject(error);
                });
            })
            .catch((data) => {
              const error = JSON.parse(JSON.stringify(data));
              console.error(error);
              reject(error);
            });
          return;
        }

        const option = new gapi.auth2.SigninOptionsBuilder();
        option.setScope(scopes ?? '');
        option.setPrompt('select_account');

        gapi.auth2
          .getAuthInstance()
          .signIn(option)
          .then((user) => {
            gapi.client.load('calendar', 'v3').then(() => {
              resolve(user.isSignedIn());
            });
          })
          .catch((data) => {
            const error = JSON.parse(JSON.stringify(data));
            console.error(error);
            reject(error);
          });
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const logout = () => {
  gapi.auth2.getAuthInstance()?.signOut();
};
