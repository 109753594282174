import { useEffect } from 'react';
import { useApp } from '../../context/AppContext';
import { useAuth } from '../useAuth';
import { userHasAccess } from './utils';
import { RequireAuthArgs } from './types';

export const useRequireAuth = (args?: RequireAuthArgs) => {
  const { error, getLoginUrl } = useAuth();
  const { isLoggedIn, user, userIsFetched, userIsLoading } = useApp();

  useEffect(() => {
    const { hasAccess, redirectUrl } = userHasAccess(
      userIsLoading,
      isLoggedIn,
      user,
      getLoginUrl(),
      args?.allowedRoles
    );
    if (hasAccess == undefined || hasAccess || !redirectUrl) {
      return;
    }

    if (args?.authRequired) {
      window.location.href = redirectUrl as string;
    }
  }, [
    args?.allowedRoles,
    args?.authRequired,
    error,
    getLoginUrl,
    isLoggedIn,
    user,
    user.roles,
    userIsFetched,
    userIsLoading,
  ]);

  return isLoggedIn;
};
