import type { LogoProps } from '@goodfynd/react-web.ui.logo';

export enum AppMode {
  ADMIN = 1,
  NORMAL = 0,
  TESTING = 2,
}

export type AppEnvironment = 'local' | 'development' | 'staging' | 'production';
export type AppEnvironmentConfig =
  | DictionaryPartial<AppEnvironment, any>
  | { local: string; development: string; production: never; staging: never };

const currentEnv = (process.env.NEXT_PUBLIC_ENVIRONMENT?.toLowerCase() ||
  'development') as AppEnvironment;
export const selectEnv = <T = string>(envs: AppEnvironmentConfig): T =>
  !!envs[currentEnv] || envs[currentEnv] === false
    ? envs[currentEnv]
    : envs.development;
const env = {
  API_HOST: selectEnv({
    local: 'https://apilocal.goodfynd.com:5200',
    development:
      process.env.NEXT_PUBLIC_GOODFYND_API || 'https://api.dev.goodfynd.com',
    staging: 'https://api.stage.goodfynd.com',
    production: 'https://api.goodfynd.com',
  }),
  APP_HOST: selectEnv({
    development: 'https://local.goodfynd.com',
    staging: 'https://events.stage.goodfynd.com',
    production: 'https://events.goodfynd.com',
  }),
  BUGSNAG_API_KEY:
    process.env.BUGSNAG_API_KEY || 'e7ba4627549e2eed5f49dc83bdda233e',
  CONSUMER_APP_HOST: selectEnv({
    development: 'https://stage.goodfynd.com',
    staging: 'https://stage.goodfynd.com',
    production: 'https://www.goodfynd.com',
  }),
  COUNTRY_CODE: process.env.COUNTRY_CODE || 'US',
  CURRENCY_CODE: process.env.CURRENCY_CODE || 'USD',
  DRIFT_INTERACTION_ID: 303036,
  EVENT_APP_HOST: selectEnv({
    development: 'https://events.stage.goodfynd.com',
    staging: 'https://events.stage.goodfynd.com',
    production: 'https://events.prod.goodfynd.com',
  }),
  FACEBOOK_APP_ID: selectEnv({
    development: '433615357924188',
  }),
  GOOGLE_API_KEY: selectEnv({
    development: 'AIzaSyBtiBVtgi0IR3XxU7fX3XkXHSbFYcAJYAo',
    production: 'AIzaSyDZNGwMNpb_1xj8jtJ7EEiEN_dRdKgqYYI',
    staging: 'AIzaSyBtiBVtgi0IR3XxU7fX3XkXHSbFYcAJYAo',
  }),
  GOOGLE_CLIENT_ID: selectEnv({
    development:
      '471368377080-lprasrk4o5m5nepfbe2k1nb371qc4j08.apps.googleusercontent.com',
    staging:
      '471368377080-lprasrk4o5m5nepfbe2k1nb371qc4j08.apps.googleusercontent.com', // DEV
    production:
      '860012868371-88cqmj086skojrj5vhhbo17m5uufptgv.apps.googleusercontent.com',
  }),
  GOOGLE_MAPS_ICON: selectEnv({
    development: 'https://stage.goodfynd.com/images/logos/pin.png',
    production: 'https://www.goodfynd.com/images/logos/pin.png',
    staging: 'https://stage.goodfynd.com/images/logos/pin.png',
  }),
  LOCALE: process.env.LOCALE || 'en-US',
  LOCALE_SHORT: process.env.LOCALE_SHORT || 'en',
  MODE: selectEnv<number>({
    development: AppMode.ADMIN,
    staging: AppMode.TESTING,
    production: AppMode.NORMAL,
  }),
  NAME: process.env.NEXT_PUBLIC_ENVIRONMENT || 'Development',
  NAME_LOWER: (
    selectEnv({
      local: 'Development',
    }) ||
    process.env.NEXT_PUBLIC_ENVIRONMENT ||
    'Development'
  ).toLowerCase() as LogoProps['environment'],
  NAME_SHORT: selectEnv({
    development: 'DEV',
    staging: 'STG',
    production: 'PROD',
  }),
  RECEIPT_HOST: selectEnv({
    local: 'https://apilocal.goodfynd.com:5200',
    development: 'https://api.stage.goodfynd.com',
    staging: 'https://api.stage.goodfynd.com',
    production: 'https://orders.goodfynd.com',
  }),
  STRIPE_CLIENT_ID: selectEnv({
    development: 'ca_FLSw4c4Uvl87O0xgNEtXKmEwrC7t7mTB',
    staging: 'ca_FLSw4c4Uvl87O0xgNEtXKmEwrC7t7mTB',
    production: 'ca_FLSwMw9n0MJadRKCyrk4XwDqU8rU9MuC',
  }),
  STRIPE_PUBLISHABLE_KEY: selectEnv({
    development: 'pk_test_iGUQHAqJsMroMaTcdp4Plxh600XvJvuAfO',
    staging: 'pk_test_iGUQHAqJsMroMaTcdp4Plxh600XvJvuAfO',
    production: 'pk_live_6sUckwUCwC7kZe1NXw11uciS00iywjApoD',
  }),
  SUPPORT_EMAIL: selectEnv({
    development: 'testers@goodfynd.com',
    staging: 'testers@goodfynd.com',
    production: 'gethelp@goodfynd.com',
  }),
  SALES_EMAIL: selectEnv({
    development: 'testers@goodfynd.com',
    staging: 'event.sales@goodfynd.com',
    production: 'event.sales@goodfynd.com',
  }),
  VENDOR_APP_HOST: selectEnv({
    development: 'https://vendors.stage.goodfynd.com',
    staging: 'https://vendors.stage.goodfynd.com',
    production: 'https://vendors.goodfynd.com',
  }),
};

export default env;
