import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import fetch from './fetch-wrapper';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * The underlying fetch instance which performs the requests.
   */
  /**
   * The underlying fetch instance which performs the requests.
   */
  fetch!: ReturnType<typeof fetch>;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.fetch = fetch(this.config);
  }
}
