import React, { createContext, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import routes from '../../config/routes';
import { useAuth } from '../../hooks/useAuth';
import useUser from '../../hooks/useUser';
import type { UserInfo } from '../../stores/models/user';

import type {
  AppContextValue,
  AppDispatchContextValue,
  AppProviderProps,
  UserProfiles,
} from './types';

export const AppContext = createContext<AppContextValue | undefined>(undefined);
export const AppDispatchContext = createContext<
  AppDispatchContextValue | undefined
>(undefined);

export default function AppProvider({ children }: AppProviderProps) {
  const router = useRouter();

  const [vendorNamesById, setVendorNamesById] = useState<
    Dictionary<string, string>
  >({});

  const defaultUserValue = useRef<UserInfo>({
    isAuthenticated: undefined,
  } as UserInfo);
  const [user, setUser] = useState<UserInfo>(defaultUserValue.current);
  const notFound = () => {
    router.push(routes.account.profile);
  };
  const {
    isFetched: userIsFetched,
    isLoading: userIsLoading,
    profiles,
    refetch: refreshAuth,
  } = useUser({ setUser, notFound });

  const {
    getLoginUrl,
    isAuthenticated,
    isLoading: identityLoading,
    logout,
    user: identity,
  } = useAuth();

  return (
    <AppContext.Provider
      value={{
        identity,
        identityLoading,
        isLoggedIn: isAuthenticated,
        profiles: profiles || ([] as unknown as UserProfiles),
        user,
        userIsFetched,
        userIsLoading,
        vendorNamesById,
      }}
    >
      <AppDispatchContext.Provider
        value={{
          logout,
          getLoginUrl,
          refreshAuth,
          setUser,
          setVendorNamesById,
        }}
      >
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}
