const routes = {
  home: '/',
  about: '/about',
  admin: {
    events: '/admin/events',
    invoices: '/admin/invoices',
    orders: '/admin/orders',
    overview: '/admin/dashboard',
    promos: '/admin/promos',
    reports: '/admin/reports',
    schedule: '/admin/schedule',
  },
  blog: 'https://blog.goodfynd.com',
  careers: 'https://www.goodfynd.com/careers',
  dashboard: {
    add: '/add',
    edit: '/dashboard/:id/edit',
    events: {
      home: '/dashboard/:id/events',
      details: '/dashboard/:id/details',
      awaitingConfirmation: '/dashboard/:id/events/awaiting-confirmation',
      confirmed: '/dashboard/:id/events/confirmed',
      interest: '/dashboard/:id/events/interest',
      upcoming: '/dashboard/:id/events/upcoming',
    },
    flyers: '/dashboard/:id/flyers',
    home: '/dashboard',
    info: {
      home: '/dashboard/:id/info',
      personnel: '/dashboard/:id/info/personnel',
    },
    orders: '/dashboard/:id/orders',
    overview: '/dashboard/:id/overview',
    reports: {
      home: '/dashboard/:id/reports',
    },
    schedule: {
      flyers: '/dashboard/:id/schedule/flyers',
      googleSync: '/dashboard/:id/schedule/google-sync',
      home: '/dashboard/:id/schedule',
      update: '/dashboard/:id/schedule/update',
    },
  },
  docs: '/docs',
  unauthorized: '/401',
  accessDenied: '/403',
  events: {
    details: '/details/:id',
  },
  invoices: '/invoices',
  maintenance: '/maintenance',
  notFound: '/404',
  organization: {
    events: '/organization/:organizationId/events',
    info: {
      home: '/organization/:organizationId/info',
      personnel: '/organization/:organizationId/info/personnel',
      territories: '/organization/:organizationId/info/territories',
    },
    orders: '/organization/:organizationId/orders',
    overview: '/organization/:organizationId/overview',
    promos: '/organization/:organizationId/promos',
    radar: '/organization/:organizationId/radar',
    reports: '/organization/:organizationId/reports',
    schedule: '/organization/:organizationId/schedule',
    settings: '/organization/:organizationId/settings',
    support: {
      home: '/organization/:organizationId/support',
    },
  },
  promos: '/promos',
  settings: {
    home: '/settings',
    ordering: '/settings',
    orderingSettingsWizard: '/settings/ordering-settings-wizard',
  },
  support: {
    home: '/support',
  },
  account: {
    callback: '/auth/callback',
    home: 'https://www.goodfynd.com/profile',
    orders: '/profile/orders',
    signIn: '/auth/login',
    logout: '/auth/logout',
    profile: '/auth/profile',
    signUp: '/auth/signup',
    unverified: '/auth/unverified',
  },
  apps: {
    android:
      'https://play.google.com/store/apps/details?id=com.goodfynd.trucks',
    ios: 'https://apps.apple.com/us/app/goodfynd/id1402316922?ls=1',
  },
  checkout: {
    home: '/checkout',
    review: '/checkout/review',
    thanks: '/checkout/thanks/:transactionIds',
  },
  help: {
    home: '/help',
    chat: '/chat',
  },
  legal: {
    home: '/legal',
    fulfillment: '/legal/fulfillment',
    privacy: '/legal/privacy',
    terms: '/legal/terms',
  },
  products: {
    home: '/products',
    addItem: '/products/add',
    editItem: '/products/edit/:id',
    category: '/vendors/:id/categories/:categoryId',
    details: '/vendors/:vendorId/products/:id',
  },
  search: {
    home: '/search',
    events: '/search/events',
    products: '/search/products',
    vendors: 'https://www.goodfynd.com/search/vendors',
  },
  social: {
    facebook: 'https://www.facebook.com/goodfynd',
    instagram: 'https://www.instagram.com/goodfynd/',
    linkedIn: 'https://www.linkedin.com/company/goodfynd',
    twitter: 'https://twitter.com/goodfynd',
  },
  success: {
    eventLead: '/success/event-lead',
    register: '/success/register',
    verifyEmail: '/success/verify-email',
    vendorLead: '/success/vendor-lead',
    vendor: '/success/vendor',
    signup: '/success/signup',
  },
  vendors: {
    add: 'https://vendors.goodfynd.com/add',
  },
};

export const driftRoutes = ['/docs/*', routes.home, '/events/details/*'];

export const externalRoutes = {
  events: {
    add: 'https://events.goodfynd.com/add',
    consumerLanding: 'https://www.goodfynd.com/events',
    myEvents: 'https://events.goodfynd.com/my-lots',
  },
  vendors: {
    add: 'https://vendors.goodfynd.com/add',
    myBusinesses: 'https://vendors.goodfynd.com/my-businesses',
  },
};

export default routes;
