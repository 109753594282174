import { useMemo } from 'react';
import * as stringUtil from '@goodfynd/react-web.utils.string-util';
import endpoints from '../../services/api/endpoints';

import type { ProductItem, ProductV2 } from '../../types/shared';
import type {
  ProductResponse,
  VendorProductV2Response,
} from '../../services/api/types';
import useFetch from '@services/api/useFetch';

export const useProductApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<ProductResponse> {
        try {
          const data: ProductResponse = await api.get(
            stringUtil.replaceAll(endpoints.products.base, {
              ':id': id,
              ':includeSchedule': '',
            }),
            undefined,
            {
              headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
              },
            }
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getVendorProducts(
        vendorId: string
      ): Promise<VendorProductV2Response> {
        try {
          const data: ProductV2[] = await api.get(endpoints.vendors.products, {
            vendorId,
            includeHidden: true,
          });
          return { success: true, products: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async toggleHidden(
        id: string,
        visible: boolean
      ): Promise<ProductResponse> {
        try {
          const data: ProductItem = await api.patch(
            stringUtil.replaceAll(endpoints.products.toggle, {
              ':id': id,
              ':visible': String(visible),
            })
          );
          return { success: true, product: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    };
  }, []);
};
