import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { dateUtil } from '@goodfynd/react-web.utils.date-util';

import { withEnvironment } from '../extensions/with-environment';

export const EventStore = types
  .model('EventStore', {
    busy: types.optional(types.boolean, false),
    search: types.optional(
      types.model().props({
        dateEnd: types.maybe(types.Date),
        dateStart: types.optional(types.Date, dateUtil.startOfDay(new Date())),
        location: types.optional(
          types.model({
            city: types.optional(types.string, ''),
            state: types.optional(types.string, ''),
            county: types.optional(types.string, ''),
            zip: types.optional(types.string, ''),
            address: types.optional(types.string, ''),
            coordinates: types.optional(types.string, ''),
          }),
          {}
        ),
        radius: types.optional(types.number, 100),
        rowsPerPage: types.optional(types.number, 6),
        term: types.optional(types.string, ''),
      }),
      {}
    ),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    setRowsPerPage: (rows: number) => {
      self.search.rowsPerPage = rows;
    },
    setSearchTerm: (term: string) => {
      self.search.term = term;
    },
    setSearchLocation: (location?: JsonLocation, radius = 100) => {
      self.search.location = cast(location ?? {});
      self.search.radius = radius;
    },
  }));
type EventStoreType = Instance<typeof EventStore>;
export type EventStore = EventStoreType;
type EventStoreSnapshotType = SnapshotOut<typeof EventStore>;
export type EventStoreSnapshot = EventStoreSnapshotType;
