import React from 'react';

import { OrganizationContext, OrganizationDispatchContext } from './OrganizationContext';

export { default as OrganizationProvider } from './OrganizationContext';
export { OrganizationContext, OrganizationDispatchContext };

export function useOrganization() {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'The useOrganization hook must be used within a OrganizationProvider.'
    );
  }
  return context;
}

export function useOrganizationDispatch() {
  const context = React.useContext(OrganizationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useOrganizationDispatch hook must be used within a OrganizationProvider.'
    );
  }
  return context;
}
