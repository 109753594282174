import { useMemo } from 'react';
import * as stringUtil from '@goodfynd/react-web.utils.string-util';

import endpoints from '../../services/api/endpoints';
import useFetch from '@services/api/useFetch';

export const useUserApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async setDefaultProfile(
        userId: string,
        lotNameId: string
      ): Promise<ResponseBase> {
        try {
          const data: ResponseBase = await api.patch(
            stringUtil.replaceAll(endpoints.user.defaultLot, {
              ':userId': userId,
              ':lotNameId': lotNameId,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async accountExists(email: string): Promise<ResponseBase> {
        try {
          await api.post(endpoints.accounts.check, { username: email });

          return { success: true };
        } catch (error: any) {
          throw { success: false, ...error };
        }
      },
    };
  }, []);
};
