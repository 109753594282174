import React, { useCallback, useState } from 'react';
import { ProfilesModal } from '@goodfynd/react-web.lib.dashboard';
import type { FilterMenuOption } from '@goodfynd/react-web.lib.ui';
import { Alert } from '@goodfynd/react-web.lib.ui';
import { useRouter } from 'next/router';

import env from '../../../config/env';
import routes from '../../../config/routes';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from '../../../utils/routes-util';
import { AutocompleteEventOrganizations } from '../../events/AutocompleteEventOrganizations';

export type ProfileType = 'lot' | 'organization' | 'vendor';

const NoProfileAlert = () => {
  const [openProfilesModal, toggleProfilesModal] = useState(false);
  const { push } = useRouter();
  const handleAddNew = useCallback(() => {
    push(routes.dashboard.add);
  }, [push]);

  const handleSelect = useCallback(
    (id: string, type: ProfileType) => {
      switch (type) {
        case 'lot':
          push(getDashboardRoutePath(routes.dashboard.events.home, id));
          break;

        case 'organization':
          push(getOrganizationRoutePath(routes.organization.overview, id));
          break;

        case 'vendor':
          window.open(`${env.VENDOR_APP_HOST}/dashboard/${id}`);
          break;

        default:
          break;
      }
    },
    [push]
  );

  return (
    <>
      <Alert
        status="warning"
        action={{
          content: 'Select Profile',
          onClick: () => toggleProfilesModal(true),
          size: 'small',
        }}
        style={{
          margin: '8px 32px',
        }}
      >
        Please select a profile from the profile switcher
      </Alert>

      <ProfilesModal
        addButtonLabel="Add event"
        close={() => toggleProfilesModal(false)}
        onAdd={handleAddNew}
        onSelect={handleSelect}
        open={openProfilesModal}
        autocompleteComponent={
          <AutocompleteEventOrganizations
            css={{ paddingBottom: 0, paddingTop: 24 }}
            onChange={(option: FilterMenuOption) => {
              handleSelect(option.value, option.type as ProfileType);
              toggleProfilesModal(false);
            }}
          />
        }
      />
    </>
  );
};

export default NoProfileAlert;
