import merge from 'lodash/merge';

import config, { AppMode, emojis, showTimedOutAlert } from '../../config';
import { useAuth } from '../../hooks/useAuth';
import { dataURItoBlob } from '../../utils/file-util';
import * as storageUtil from '../../utils/storage-util';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';

const IS_SERVER = typeof window === 'undefined';
//let URLSearchParams = window.URLSearchParams;

const { app, defaultLocation, storageKeys } = config;

const getFormData = (body: any) => {
  const formData = new FormData();
  for (const key in body) {
    // skip loop if the property is from prototype
    if (!body.hasOwnProperty(key)) {
      continue;
    }

    let value = body[key];
    if (value instanceof Date) {
      value = new Date(value).toISOString();
    }

    if (Array.isArray(value)) {
      value = value.map((indexValue) =>
        indexValue instanceof Date
          ? new Date(indexValue).toISOString()
          : indexValue
      );
    }

    if (
      (key === 'file' || key === 'image' || key === 'logoFile') &&
      typeof value === 'string'
    ) {
      value = dataURItoBlob(value);
    }

    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  }

  return formData;
};

export default function useFetch({
  timeout,
  ...config
}: ApiConfig = DEFAULT_API_CONFIG) {
  const { getAccessToken } = useAuth();

  const getDefaultOptions = async (
    config?: RequestConfig,
    useDefaultHeaders = false
  ): Promise<RequestConfig> => ({
    method: 'GET',
    // timeout: 31000,
    ...config,
    headers: {
      ...(useDefaultHeaders && (await getDefaultHeaders())),
      ...config?.headers,
    },
  });

  const getDefaultHeaders = async (): Promise<AppHeaders> => {
    if (IS_SERVER) {
      return {};
    }

    const storedLocation = storageUtil.local.get<ItemLocation>(
      storageKeys.userLocation
    );

    const accessToken = await getAccessToken();
    return {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'X-Coordinates':
        storedLocation?.coordinates ?? defaultLocation.coordinates,
      'X-Device': 'web',
      'X-Guest-Order': storageUtil.local.get(storageKeys.guestOrder, ''),
      'X-Mode': app.mode.isProduction ? AppMode.Admin : AppMode.Testing,
      'X-Offset': new Date().getTimezoneOffset(),
    };
  };

  return {
    get: async (
      path: string,
      params?: RequestParams,
      options?: RequestConfig
    ) => {
      const requestOptions = await getDefaultOptions(
        undefined,
        config.useDefaultHeaders
      );
      const requestConfigMerged = merge(requestOptions, options);
      return await sendRequest(
        getEndpoint(path, config.baseURL, params),
        requestConfigMerged
      );
    },
    post: async (path: string, body?: any, options?: RequestConfig) => {
      const requestOptions = await getDefaultOptions(
        {
          method: 'POST',
          body: JSON.stringify(body),
          timeout,
        },
        config.useDefaultHeaders
      );
      const requestConfigMerged = merge(requestOptions, options);
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestConfigMerged
      );
    },
    postFile: async (path: string, body?: object, options?: RequestConfig) => {
      const accessToken = await getAccessToken();
      const requestOptions = await getDefaultOptions({
        method: 'POST',
        body: getFormData(body),
        timeout,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const requestConfigMerged = merge(requestOptions, options);
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestConfigMerged
      );
    },
    put: async (path: string, body?: object) => {
      const requestOptions = await getDefaultOptions(
        {
          method: 'PUT',
          body: JSON.stringify(body),
          timeout,
        },
        config.useDefaultHeaders
      );
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestOptions
      );
    },
    putFile: async (path: string, body?: any) => {
      const accessToken = await getAccessToken();
      const requestOptions = await getDefaultOptions({
        method: 'PUT',
        body: getFormData(body),
        timeout,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestOptions
      );
    },
    patch: async (path: string, body?: object) => {
      const requestOptions = await getDefaultOptions(
        {
          method: 'PATCH',
          body: JSON.stringify(body),
          timeout,
        },
        config.useDefaultHeaders
      );
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestOptions
      );
    },
    patchFile: async (path: string, body?: object) => {
      const accessToken = await getAccessToken();
      const requestOptions = await getDefaultOptions({
        method: 'PATCH',
        body: getFormData(body),
        timeout,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestOptions
      );
    },
    delete: async (path: string, body?: object) => {
      const requestOptions = await getDefaultOptions(
        {
          method: 'DELETE',
          body: JSON.stringify(body),
          timeout,
        },
        config.useDefaultHeaders
      );
      return await sendRequest(
        getEndpoint(path, config.baseURL),
        requestOptions
      );
    },
  };
}

type RequestParams = { [key in string]: any };
export type RequestConfig = {
  body?: any;
  data?: any;
  handleResponse?: (response: Response) => Promise<any>;
  headers?: any;
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH';
  params?: RequestParams;
  timeout?: number;
};

type AppHeaders = {
  Accept?: string;
  Authorization?: string;
  'Content-Type'?: string;
  'X-App-Version'?: string;
  'X-Coordinates'?: string;
  'X-Device'?: 'android' | 'ios' | 'web';
  'X-Guest-Order'?: string;
  'X-Offset'?: number | string;
  'X-Mode'?: AppMode;
  'X-Sector'?: number | string;
};

const getEndpoint = (path: string, host?: string, params?: RequestParams) => {
  let endpoint = host ? `${host}/${path}` : path;
  if (params) {
    const urlParams = new URLSearchParams(params);
    endpoint += `?${urlParams.toString()}`;
  }
  return endpoint;
};

const sendRequest = async (
  endpoint: string,
  {
    handleResponse = handleResponseDefault,
    timeout = 0,
    ...config
  }: RequestConfig
) => {
  // Log request details
  // console.debug(
  //   `...API request start @ ${new Date().toLocaleString()}:\n`,
  //   endpoint
  // );

  const controller = IS_SERVER ? null : new AbortController();
  const signal = controller?.signal;
  const promise = fetch(endpoint, {
    ...config,
    credentials: endpoint.includes('.goodfynd.com') ? 'include' : 'same-origin',
    signal,
  });

  if (signal) {
    signal.addEventListener('abort', () => controller.abort());
  }

  // console.debug('...starting request timeout...');
  const requestTimeout =
    timeout > 0 &&
    setTimeout(() => {
      console.debug(
        `${timeout}...request timed out! ${emojis.sad}`.toUpperCase()
      );

      showTimedOutAlert();
      controller?.abort();
    }, timeout);

  return promise
    .then(async (response) => await handleResponse(response))
    .catch((error) => {
      !['aborterror', 'server'].includes(error?.name?.toLowerCase()) &&
        !error.unauthorized &&
        console.warn(error);
      return Promise.reject(
        error?.data || error.unauthorized
          ? { ...error?.data, unauthorized: true }
          : {}
      );
    })
    .finally(() => {
      if (requestTimeout) {
        console.debug('...clearing request timeout...');
        clearTimeout(requestTimeout);
      }
    });
};

async function handleResponseDefault(response: Response) {
  const text = await response.text();
  const data = text && JSON.parse(text);
  // response.status &&
  //   console.debug('***RESPONSE.STATUS', response.status, response.statusText);
  if (response.ok) {
    return data;
  }

  if (response.status === 401) {
    return Promise.reject({
      data,
      unauthorized: true,
    });
  }

  if (response.status === 404) {
    // Redirect to custom 404 page
    // window.location.href = routes.notFound;
    return Promise.reject({ data, notFound: true });
  }
  if (response.status < 499) {
    return Promise.reject({ data: { ...data, status: response.status } });
  }
  const error = data || response.statusText;
  console.debug('...API ERROR: ', error);
  return Promise.reject({ data: error, name: 'server' });
}
