import React from 'react';

import { LocationContext, LocationDispatchContext } from './LocationContext';

export { default as LocationProvider } from './LocationContext';

export { LocationContext, LocationDispatchContext };

export function useLocation() {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error('The useLocation hook must be used within a LocationProvider.');
  }
  return context;
}

export function useLocationDispatch() {
  const context = React.useContext(LocationDispatchContext);
  if (context === undefined) {
    throw new Error('The useLocationDispatch hook must be used within a LocationProvider.');
  }
  return context;
}
