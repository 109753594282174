import type { Instance, SnapshotOut } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { EventStore } from '../event-store';
import { withEnvironment } from '../extensions/with-environment';
import { OrderStore } from '../order-store';
import { ScheduleStore } from '../schedule-store';

export { useStores } from './root-store-context';

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore', {
    eventId: types.maybe(types.string),
    organizationId: types.maybe(types.string),
    eventStore: types.optional(EventStore, {}),
    orderStore: types.optional(OrderStore, {}),
    scheduleStore: types.optional(ScheduleStore, {}),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setEvent: (id: string) => {
      self.eventId = id;
    },
    setOrganization: (id: string) => {
      self.organizationId = id;
    },
    unsetEvent: () => {
      self.eventId = '';
    },
    unsetOrganization: () => {
      self.organizationId = '';
    },
  }));

type RootStoreType = Instance<typeof RootStoreModel>;

/**
 * The RootStore instance.
 */
export type RootStore = RootStoreType;
type RootStoreSnapshotType = SnapshotOut<RootStore>;
export type RootStoreSnapshot = RootStoreSnapshotType;

export const DefaultState = {
  eventStore: {},
  orderStore: {},
  scheduleStore: {},
} as RootStore;
