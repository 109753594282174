export const mapGeoResult = (
  result: google.maps.GeocoderResult
): JsonLocation => {
  const { formatted_address, geometry, address_components } = result;
  const { location: geoLocation } = geometry;

  const latitude = geoLocation.lat();
  const longitude = geoLocation.lng();
  return address_components.reduce<JsonLocation>(
    (acc, { types, short_name, long_name }) => {
      switch (types[0]) {
        case 'locality':
          acc.city = short_name;
          break;
        case 'administrative_area_level_1':
          acc.state = long_name;
          break;
        case 'administrative_area_level_2':
          acc.county = short_name;
          break;
        case 'postal_code':
          acc.postalCode = short_name;
          break;
      }
      return acc;
    },
    {
      address: formatted_address,
      coordinates: `${latitude}, ${longitude}`,
      latitude,
      longitude,
    }
  );
};
