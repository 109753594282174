export const bg = {
  careersHeader: '/images/bg/careers-header.jpg',
  careersHeaderMobile: '/images/bg/careers-header-mobile.jpg',
  catering: '/images/bg/catering-bg.jpg',
  panelSearch: '/images/bg/panel-search-bg.jpg',
  panelEarnMore: '/images/bg/panel-earn-more-bg.jpg',
  panelFoodTrucks: '/images/bg/panel-food-trucks-bg.jpg',
  panelGatherSafely: '/images/bg/panel-gather-safely-bg.jpg',
  panelSimplifyCatering: '/images/bg/panel-simplify-catering.jpg',
  signup: '/images/bg/signup-bg.jpg',
  signupMobile: '/images/bg/signup-bg-mobile.jpg',
};

export const creditCards = {
  dark: {
    amazon: '/images/payment/methods/dark/amazon.webp',
    americanexpress: '/images/payment/methods/dark/americanexpress.png',
    'american-express': '/images/payment/methods/dark/americanexpress.png',
    diners: '/images/payment/methods/dark/diners.webp',
    'diners-club': '/images/payment/methods/dark/diners.webp',
    discover: '/images/payment/methods/dark/discover.png',
    ebay: '/images/payment/methods/dark/ebay.webp',
    googlewallet: '/images/payment/methods/dark/googlewallet.png',
    jcb: '/images/payment/methods/dark/jcb.webp',
    mastercard: '/images/payment/methods/dark/mastercard.png',
    'master-card': '/images/payment/methods/dark/mastercard.png',
    paypal: '/images/payment/methods/dark/paypal.png',
    shopify: '/images/payment/methods/dark/shopify.webp',
    unionpay: '/images/payment/methods/dark/unionpay.webp',
    vendor: '/images/payment/payment-methods/apple-pay-android-pay.png',
    visa: '/images/payment/methods/dark/visa.png',
    westernunion: '/images/payment/methods/dark/westernunion.webp',
    worldpay: '/images/payment/methods/dark/worldpay.webp',
  },
  light: {
    amazon: '/images/payment/methods/light/amazon.webp',
    americanexpress: '/images/payment/methods/light/americanexpress.png',
    'american-express': '/images/payment/methods/light/americanexpress.png',
    diners: '/images/payment/methods/light/diners.webp',
    'diners-club': '/images/payment/methods/light/diners.webp',
    discover: '/images/payment/methods/light/discover.png',
    ebay: '/images/payment/methods/light/ebay.webp',
    googlewallet: '/images/payment/methods/light/googlewallet.png',
    jcb: '/images/payment/methods/light/jcb.webp',
    mastercard: '/images/payment/methods/light/mastercard.png',
    'master-card': '/images/payment/methods/light/mastercard.png',
    paypal: '/images/payment/methods/light/paypal.png',
    shopify: '/images/payment/methods/light/shopify.webp',
    unionpay: '/images/payment/methods/light/unionpay.webp',
    vendor: '/images/payment/payment-methods/apple-pay-android-pay.png',
    visa: '/images/payment/methods/light/visa.png',
    westernunion: '/images/payment/methods/light/westernunion.webp',
    worldpay: '/images/payment/methods/light/worldpay.webp',
  },
};

export const fallbacks = {
  event: {
    event1: '/images/fallbacks/event-fallback-1.jpg',
    event2: '/images/fallbacks/event-fallback-2.jpg',
    event3: '/images/fallbacks/event-fallback-3.jpg',
    event4: '/images/fallbacks/event-fallback-4.jpg',
  },
  product: '/images/fallbacks/product-fallback.jpg',
};

export const icons = {
  android: '/images/icons/android.png',
  health: '/images/icons/health.png',
  ios: '/images/icons/ios.png',
  lifestyle: '/images/icons/lifestyle.png',
  pdf: '/images/icons/pdf.png',
  people: '/images/icons/people.png',
  perks: '/images/icons/perks.png',
  proDev: '/images/icons/pro-dev.png',
  pto: '/images/icons/pto.png',
  remoteWork: '/images/icons/remote-work.png',
  schedule: '/images/icons/schedule.png',
  speaker: '/images/icons/speaker.png',
  web: '/images/icons/web.png',
};

export const misc = {
  burger: '/images/misc/burger.png',
  calendar: '/images/misc/calendar.png',
  foodGalaxy: '/images/misc/food-galaxy.png',
  noEvents: '/images/misc/no-events.png',
  orderFlow: '/images/misc/order-flow.png',
  orderFlowMobile: '/images/misc/order-flow-mobile.png',
  shmoney: '/images/misc/shmoney.gif',
  truckList: '/images/misc/truck-list.png',
};

export const payment = {
  androidPay: '/images/payment/android-pay.png',
  applePay: '/images/payment/apple-pay.png',
};

export const phones = {
  phoneBookTruck: '/images/phones/phone-book-truck.png',
  phoneNoFussCatering: '/images/phones/phone-no-fuss-catering.png',
  phoneSearchScrolled: '/images/phones/phone-search-scrolled.png',
  phoneSignUp: '/images/phones/phone-sign-up.png',
  phoneTakeControl: '/images/phones/phone-edit-item.png',
  phoneTiltLeft: '/images/phones/phone-tilt-left.png',
  phoneTiltRight: '/images/phones/phone-tilt-right.png',
  phoneTruckResultsList: '/images/phones/phone-truck-results-list.png',
};

export const quotes = {
  acSliders: '/images/quotes/ac-sliders.jpg',
  dcSlices: '/images/quotes/dc-slices.jpg',
  newGourmet: '/images/quotes/new-gourmet.jpg',
  pepe: '/images/quotes/pepe.jpg',
  quotes1: '/images/quotes/quote1.jpg',
  quotes2: '/images/quotes/quote2.jpg',
  quotes3: '/images/quotes/quote3.jpg',
};

const images = {
  bg,
  fallbacks,
  creditCards,
  icons,
  misc,
  payment,
  phones,
  quotes,
  logoHorizontalW: '/images/logos/logo-horizontal-w.png',
  logoHorizontal: '/images/logos/logo-horizontal.png',
  pinW: '/images/logos/pin-w.png',
  pin: '/images/logos/pin.png',
};

export default images;
