import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

export const OrderStore = types
  .model('OrderStore', {
    blockedOrders: types.optional(types.array(types.string), []),
    busy: types.optional(types.boolean, false),
    refetchOrders: types.optional(types.boolean, false),
    refreshTimeout: types.maybe(types.number),
    search: types.optional(
      types.model().props({
        rowsPerPage: types.optional(types.number, 6),
        term: types.optional(types.string, ''),
      }),
      {}
    ),
    vendorId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
  }))
  .actions((self) => ({
    block: (id: string) => {
      self.blockedOrders.push(id);
    },
    setRefreshTimeout: (timeout: number) => {
      self.refreshTimeout = timeout;
    },
    setRowsPerPage: (rows: number) => {
      self.search.rowsPerPage = rows;
    },
    setSearchTerm: (term: string) => {
      self.search.term = term;
    },
    toggleRefetchOrders: (refetch: boolean) => {
      self.refetchOrders = refetch;
    },
    unblock: (id: string) => {
      self.blockedOrders = cast(
        self.blockedOrders.filter((orderId) => orderId !== id)
      );
    },
  }));

type OrderStoreType = Instance<typeof OrderStore>;
export type OrderStore = OrderStoreType;
type OrderStoreSnapshotType = SnapshotOut<typeof OrderStore>;
export type OrderStoreSnapshot = OrderStoreSnapshotType;
