import { useMemo, useRef } from 'react';
import { useQuery } from 'react-query';

import config from '../../config';
import { useEventApi } from '../../hooks/api';

import type { LotEventsResponse } from '../../services/api/types';
import type { Event, EventVendor } from '../../types/events';
import type { DateStats } from './types';
export default function useLotEvents(lotId: string) {
  const api = useEventApi();
  const dateRecords = useRef<Record<string, DateStats>>({});
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    LotEventsResponse,
    unknown
  >(
    [config.queryKeys.lotEvents, lotId],
    () => {
      return api.getByLot(lotId);
    },
    {
      enabled: !!lotId,
    }
  );

  const [awaiting, confirmed, registered] = useMemo<
    Array<Event & EventVendor>[]
  >(() => {
    const awaiting: Array<Event & EventVendor> = [];
    const confirmed: Array<Event & EventVendor> = [];
    const registered: Array<Event & EventVendor> = [];

    if (!data?.events) {
      return [awaiting, confirmed, registered];
    }

    dateRecords.current = {};
    data.events.forEach((event) => {
      const date = event.startDate;
      event.vendors.forEach((eventVendor) => {
        if (eventVendor.status === 'registered') {
          registered.push({ ...event, ...eventVendor });
          dateRecords.current[date]
            ? dateRecords.current[date].registered.push(eventVendor)
            : (dateRecords.current[date] = {
                confirmed: [],
                registered: [eventVendor],
                rejected: [],
                selected: [],
              });
        } else if (eventVendor.status === 'rejected') {
          dateRecords.current[date]
            ? dateRecords.current[date].rejected.push(eventVendor)
            : (dateRecords.current[date] = {
                confirmed: [],
                registered: [],
                rejected: [eventVendor],
                selected: [],
              });
        } else if (eventVendor.status === 'selected') {
          awaiting.push({ ...event, ...eventVendor });
          dateRecords.current[date]
            ? dateRecords.current[date].selected.push(eventVendor)
            : (dateRecords.current[date] = {
                confirmed: [],
                registered: [],
                rejected: [],
                selected: [eventVendor],
              });
        } else if (eventVendor.status === 'confirmed') {
          confirmed.push({ ...event, ...eventVendor });
          dateRecords.current[date]
            ? dateRecords.current[date].confirmed.push(eventVendor)
            : (dateRecords.current[date] = {
                confirmed: [eventVendor],
                registered: [],
                rejected: [],
                selected: [],
              });
        }
      });
    });

    return [awaiting, confirmed, registered];
  }, [data?.events, dateRecords]);

  return {
    awaiting,
    confirmed,
    dateRecords: dateRecords.current,
    events: data?.events || [],
    isFetched,
    isFetching,
    isLoading,
    refetch,
    registered,
  };
}
