import { useEffect, useState } from 'react';

import { Api } from '../services/api/api';
import { RequestConfig } from '../services/api/fetch-wrapper';
import useIsMounted from './useIsMounted';

export default function useFetch<T>(
  url: string,
  options?: RequestConfig,
  startImmediately = true
) {
  const api = new Api({});

  const isMounted = useIsMounted();
  const [busy, setBusy] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState();

  const start = async () => {
    if (busy) {
      return;
    }

    setBusy(true);

    let fetchMethod = api.fetch.get;
    switch (options?.method) {
      case 'DELETE':
        fetchMethod = api.fetch.delete;
        break;

      case 'POST':
        fetchMethod = api.fetch.post;
        break;

      case 'PUT':
        fetchMethod = api.fetch.put;
        break;

      default:
        break;
    }

    const mounted = isMounted();
    try {
      const data = await fetchMethod(url, options);
      if (mounted) {
        setData(data);
        setError(undefined);
      }
    } catch (error: any) {
      console.debug(error);
      if (mounted) {
        setError(error);
        setData(undefined);
      }
    } finally {
      mounted && setBusy(false);
    }
  };

  useEffect(() => {
    startImmediately && !data && start();
  }, [isMounted, options, url]);

  return { busy, error, data, start };
}
