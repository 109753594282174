import { useMemo } from 'react';
import { useQuery } from 'react-query';

import config from '../config';
import { useAccountApi } from './api';

import type {
  UserInfoError,
  UserInfoResponse,
} from '../stores/auth-store/types';
import type { UserInfo } from '../stores/models/user';

type UserHookOptions = {
  setUser: (user: UserInfo) => void;
  notFound: () => void;
};

export default function useUser({ setUser, notFound }: UserHookOptions) {
  const api = useAccountApi();
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    UserInfoResponse,
    UserInfoError,
    UserInfoResponse
  >(
    [config.queryKeys.auth],
    async () => {
      return await api.userInfo();
    },
    {
      onError: (err) => {
        switch (err.status) {
          case 404:
            notFound();
          default:
            return;
        }
      },
      onSuccess: (data) => {
        setUser(data.user);
      },
    }
  );

  const user = data?.user;

  return {
    isFetched,
    isFetching,
    isLoading,
    isLoggedIn: user?.isAuthenticated || false,
    profileCount: useMemo(
      () => user?.profiles.length || 0,
      [user?.profiles.length]
    ),
    profiles: user?.profiles,
    refetch,
    user,
  };
}
