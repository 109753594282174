import type { CSS } from '@goodfynd/react-web.theme';

import type {
  CustomerOverviewValue,
  OrdersOverviewContextValue,
} from '../components/overview/OrdersOverviewContext/types';
import { DefaultColors } from '@goodfynd/react-web.theme';
import { dateUtil } from '@goodfynd/react-web.utils.date-util';
import { asCurrency, numberUtil } from '@goodfynd/react-web.utils.number-util';
import { displayDay } from '@goodfynd/react-web.utils.string-util';

import config from '../config';

import type { CartOrder, OrderType } from '@goodfynd/react-web.types';
export const calculateInstantPayoutFee = (payoutAmount: number): number => {
  const feePercentage = 0.0125;
  return Number(numberUtil.getCurrency(payoutAmount * feePercentage));
};

export const getCancelReasons = (
  order: CartOrder
): { label: string; value: string }[] => {
  return order.type === 'invoice'
    ? []
    : [
        {
          label: 'Ran out of...',
          value: `Sorry! We ran out of \n\n- ${order.merchant.name}`,
        },
        {
          label: 'Mechanical Issues',
          value: `Sorry our truck is experiencing mechanical issues and we have to reschedule ${config.emojis.sad}.\n\n- ${order.merchant.name}`,
        },
        {
          label: 'Rescheduling',
          value: `Sorry, this event is being rescheduled for a later date ${config.emojis.sad}.\n\n- ${order.merchant.name}`,
        },
      ];
};

export const getCustomMessages = (
  order: CartOrder
): { label: string; value: string }[] => {
  return order.type === 'invoice'
    ? []
    : [
        {
          label: 'Order ready!',
          value: `Great news from Goodfynd! Your order for ${order.merchant.name} is ready for pickup.`,
        },
        {
          label: 'Ran out of...',
          value: `Sorry! We ran out of\n\n- ${order.merchant.name}`,
        },
        {
          label: 'Running late',
          value: `Sorry we're running late! We will get your food to you ASAP.\n\n- ${order.merchant.name}`,
        },
      ];
};

export const getIncludedTax = (price: number, taxRate: number): number => {
  const priceBeforeTax = getTaxIncludedPrice(price, taxRate);
  const taxAmount = price - priceBeforeTax;
  return asCurrency(taxAmount);
};

export const getTaxIncludedPrice = (price: number, taxRate: number): number => {
  const taxDivisor = 1 + taxRate;
  const priceBeforeTax = price / taxDivisor;
  return asCurrency(priceBeforeTax);
};

export const getInvoicePaymentUrl = (
  id: string,
  fallbackUrl?: string
): string | undefined => {
  switch (config.env.NAME_LOWER) {
    case 'development':
    case 'staging':
      return `https://invoice.stage.goodfynd.com/${id}`;

    case 'production':
      return `https://invoice.goodfynd.com/${id}`;

    default:
      return fallbackUrl;
  }
};

export const getInvoicedTime = (order: CartOrder): string => {
  const isPaid = order.step === 4;
  const isReminded = order.totalNotifications > 0;
  let action = isReminded ? 'Reminded' : 'Sent';
  if (isPaid) {
    action = 'Paid';
  }

  const time = isPaid || isReminded ? order.timeUpdated : order.time;
  const dayDisplay = displayDay(new Date(time), config.dateFormats.time);
  switch (dayDisplay) {
    case 'Today':
      return `${action} Today`;

    case 'Yesterday':
      return `${action} Yesterday`;

    default:
      return `${action} ${dateUtil.format(
        time,
        config.dateFormats.monthDayYear
      )}`;
  }
};

export const getOrderName = (
  order: CartOrder,
  includePhone = false
): string => {
  const ticketNumber = order.ticketNumber
    ? `Ticket #${order.ticketNumber}`
    : '';
  return order.customer?.fullName || order.customer?.phone || ticketNumber
    ? order.customer?.fullName ||
        (includePhone ? order.customer?.phone : '') ||
        ticketNumber
    : 'No Customer';
};

export const getOrderedTime = (order: CartOrder): string => {
  const time = order.time;
  const dayDisplay = displayDay(new Date(time), config.dateFormats.time);
  const timeDisplay = dateUtil.format(time, config.dateFormats.time);
  switch (dayDisplay) {
    case 'Today':
      return `Ordered at ${timeDisplay}`;

    case 'Yesterday':
      return `Ordered yesterday at ${timeDisplay}`;

    default:
      return `Ordered ${dateUtil.format(
        time,
        config.dateFormats.monthDayYear
      )} at ${timeDisplay}`;
  }
};

export const getPickupTime = (order: CartOrder): string => {
  const time = new Date(order.pickupTime || order.time);
  const dayDisplay = displayDay(time, config.dateFormats.monthDayYear);

  if (time < dateUtil.startOfDay(new Date())) {
    return dayDisplay;
  }

  switch (dayDisplay) {
    case 'Today':
      return '';

    default:
      return getOrderedTime(order);
  }
};

export type OrderStatusLabel =
  | 'Abandoned'
  | 'Canceled'
  | 'Completed'
  | 'New'
  | 'Notified'
  | 'Not Started'
  | 'Partially Refunded'
  | 'Refunded'
  | 'Reminded'
  | 'Sent'
  | 'Started'
  | 'Unknown';
export const getStatusLabel = (
  order: CartOrder,
  isGoodfyndAdmin = false
): OrderStatusLabel => {
  const isInvoice = order.type === 'invoice';
  const orderTotal = isGoodfyndAdmin ? order.total : order.vendorTotal;
  const sectionDictionary: { [key: string]: OrderStatusLabel } = {
    '1': 'New',
    '2': isInvoice ? 'Sent' : 'Started',
    // '3': isInvoice ? 'Sent' : 'Not Started',
    '3': isInvoice ? 'Reminded' : 'Notified',
    '4': 'Completed',
    '91': 'Canceled',
    '93': 'Refunded',
    '95': 'Abandoned',
  };
  return order.refund > 0 && order.refund < orderTotal
    ? 'Partially Refunded'
    : sectionDictionary[order.step.toString()] || 'Unknown';
};

export const getStatusTagCss = (status: string): CSS => {
  if (typeof status !== 'string') {
    return {};
  }

  switch (status?.toLowerCase()) {
    case 'complete':
    case 'walk up':
      return {
        backgroundColor: '$GreenLightest',
        color: DefaultColors.GreenBrand,
      };

    case 'expired':
      return {
        backgroundColor: '$RedLightest',
        color: '$RedBrand',
      };

    case 'ready':
      return {
        backgroundColor: '$YellowBrand',
        color: DefaultColors.Neutral13,
      };

    default:
      return {};
  }
};

export const getSum = (
  orders: CartOrder[],
  property:
    | (keyof CartOrder & 'itemTotal')
    | 'subTotal'
    | 'taxAmount'
    | 'total'
    | 'totalFees'
    | 'vendorTotal' = 'vendorTotal'
): number => {
  return orders.map((order) => order[property]).reduce((a, b) => a + b, 0);
};

export const getTypeLabel = (type: OrderType): string => {
  switch (type) {
    case 'donation':
      return 'Donation';

    case 'invoice':
      return 'Invoice';

    case 'online':
      return 'Online';

    case 'walkup':
      return 'Credit';

    case 'walkup-cash':
      return 'Cash';

    case 'walkup-report':
      return 'Report';

    default:
      return 'Unknown';
  }
};

export const getOverviewInfo = (
  orders: CartOrder[],
  totalProperty: 'total' | 'vendorTotal' = 'vendorTotal',
  chunks = 10
): {
  customers: {
    newCount: number;
    newGross: number;
    newItems: number;
    repeatCount: number;
    repeatGross: number;
    repeatItems: number;
  };
  grossTotals: number[];
  items: { [key: string]: { count: number; gross: number } };
  newCustomers: {
    [key: string]: CustomerOverviewValue;
  };
  repeatCustomers: {
    [key: string]: CustomerOverviewValue;
  };
  ticketTotals: number[];
  totalItems: number;
} => {
  const customers = {
    newCount: 0,
    newGross: 0,
    newItems: 0,
    repeatCount: 0,
    repeatGross: 0,
    repeatItems: 0,
  };
  const grossTotals: number[] = [];
  const items: OrdersOverviewContextValue['items'] = {};
  const newCustomers: OrdersOverviewContextValue['newCustomers'] = {};
  const repeatCustomers: OrdersOverviewContextValue['repeatCustomers'] = {};
  const ticketTotals: number[] = [];
  let totalItems = 0;
  const totalOrders = orders.length;
  const chunkSize = Math.ceil(totalOrders / chunks);
  let grossTotal = 0;
  let ticketTotal = 0;
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    const orderItemTotal = order.itemTotal;
    const orderTotal = order.vendorTotal;

    grossTotal += order[totalProperty];
    ticketTotal++;
    totalItems += order.itemTotal;

    if (i % chunkSize === 0) {
      grossTotals.push(grossTotal);
      ticketTotals.push(ticketTotal);
      grossTotal = 0;
      ticketTotal = 0;
    }

    const customerName = order.customer.fullName || `Unknown-${i}`;
    const customerId = order.customer.id || customerName;
    if (!newCustomers[customerId]) {
      newCustomers[customerId] = {
        gross: orderTotal,
        name: customerName,
        items: orderItemTotal,
        tickets: 1,
      };
      customers.newCount++;
      customers.newGross += orderTotal;
      customers.newItems += orderItemTotal;
    } else if (!repeatCustomers[customerId]) {
      repeatCustomers[customerId] = {
        gross: orderTotal,
        name: customerName,
        items: orderItemTotal,
        tickets: 1,
      };
      customers.repeatCount++;
      customers.repeatGross += orderTotal;
      customers.repeatItems += orderItemTotal;
    } else {
      repeatCustomers[customerId].gross += orderTotal;
      repeatCustomers[customerId].items += orderItemTotal;
      repeatCustomers[customerId].tickets++;
      customers.repeatCount++;
      customers.repeatGross += orderTotal;
      customers.repeatItems += orderItemTotal;
    }

    order.items.forEach((item) => {
      if (!items[item.name]) {
        items[item.name] = {
          count: 0,
          gross: 0,
        };
      }

      items[item.name].count += item.price.quantity;
      items[item.name].gross += item.price.value * item.price.quantity;
    });
  }

  grossTotal > 0 && grossTotals.push(grossTotal);
  ticketTotal > 0 && ticketTotals.push(ticketTotal);

  console.debug(
    `trends (grossTotals,ticketTotals)...chunks:chunkSize(${chunks}:${chunkSize})...`,
    ticketTotals,
    grossTotals
  );
  return {
    customers,
    grossTotals,
    items,
    newCustomers,
    repeatCustomers,
    ticketTotals,
    totalItems,
  };
};
