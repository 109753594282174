export const dataURItoBlob = (base64: string) => {
  if (!base64) {
    return undefined;
  }

  let byteString;
  if (base64.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(base64.split(',')[1]);
  else byteString = unescape(base64.split(',')[1]);

  // separate out the mime component
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {
    type: mimeString,
  });
};

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
