import { useMemo } from 'react';
import { stringUtil } from '@goodfynd/react-web.utils.string-util';
import endpoints from '../../services/api/endpoints';

import type { OrganizationStructure } from '@goodfynd/react-web.dashboard.organization-tree';
import type { AutocompleteRequest } from '../../types/search';
import type {
  OrganizationItem,
  OrganizationTerritory,
  Suggestion,
} from '../../types/shared';
import type {
  AutoCompleteLotsResponse,
  AutoCompleteResponse,
  AutoCompleteVendorsResponse,
} from '../../services/api/types';
import useFetch from '@services/api/useFetch';
export const useOrganizationApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.organizations.autocomplete,
            request
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async autocompleteLots(
        request: AutocompleteRequest
      ): Promise<AutoCompleteLotsResponse> {
        try {
          const data: AutoCompleteLotsResponse = await api.get(
            endpoints.organizations.autocompleteLotsAndOrganizations,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async autocompleteVendors(
        request: AutocompleteRequest
      ): Promise<AutoCompleteVendorsResponse> {
        try {
          const data: AutoCompleteVendorsResponse = await api.get(
            endpoints.organizations.autocompleteLotsAndOrganizations,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async get(id: string): Promise<OrganizationItem> {
        try {
          const data: OrganizationItem = await api.get(
            stringUtil.replace(endpoints.organizations.base, { ':id': id })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getStructure(
        organizationId: string
      ): Promise<OrganizationStructure> {
        try {
          const data: OrganizationStructure = await api.get(
            stringUtil.replace(endpoints.organizations.structure, {
              ':id': organizationId,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getTerritories(
        organizationId: string
      ): Promise<OrganizationTerritory[]> {
        try {
          const data: OrganizationTerritory[] = await api.get(
            endpoints.organizations.territories,
            { organizationId }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, []);
};
