import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient } from 'react-query';

import { stringUtil } from '@goodfynd/react-web.lib.utils';

import config from '../../config';
import routes from '../../config/routes';
import { useOrganizationApi } from '../../hooks/api';
import { useStores } from '../../stores';
import * as baseUtil from '../../utils/base';
import { useLocationDispatch } from '../LocationContext';

import type { OrganizationItem } from '../../types/shared';
import type {
  OrganizationContextValue,
  OrganizationDispatchContextValue,
  OrganizationProviderProps,
} from './types';
export const OrganizationContext = createContext<
  OrganizationContextValue | undefined
>(undefined);
export const OrganizationDispatchContext = createContext<
  OrganizationDispatchContextValue | undefined
>(undefined);

export default observer(function OrganizationProvider({
  children,
}: OrganizationProviderProps) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { setOrganization } = useStores();

  const { setPosition } = useLocationDispatch();

  // Fetch organization info
  const api = useOrganizationApi();
  const organizationId = router.query.organizationId as string;
  const queryKey: QueryKey = useMemo(
    () => [config.queryKeys.organization, organizationId],
    [organizationId]
  );
  const {
    data: organization = {} as OrganizationItem,
    isLoading,
    isFetching,
    refetch: refetchOrganization,
  } = useQuery<OrganizationItem, unknown, OrganizationItem>(
    queryKey,
    () => api.get(organizationId as string),
    {
      enabled: !!organizationId,
      onSuccess(organization) {
        organization && setOrganization(organization.nameId);
      },
    }
  );

  const invalidateOrganization = useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: (query) => isEqual(query.queryKey, queryKey),
    });
  }, [queryClient, queryKey]);

  useEffect(() => {
    if (organization.location?.position) {
      const { latitude, longitude } =
        organization.location.position.coordinates;
      setPosition({
        latitude,
        longitude,
      });
    }
  }, [organization.location, setPosition]);

  return (
    <OrganizationContext.Provider
      value={{
        isLoading: isLoading || isFetching,
        organization,
        organizationId: organization.id,
        organizationNameId: organization.nameId,
      }}
    >
      <OrganizationDispatchContext.Provider
        value={{
          changeOrganization: useCallback(
            (orgId: string) => {
              router.push(
                stringUtil.replace(routes.organization.overview, {
                  ':organizationId': orgId,
                })
              );
            },
            [router]
          ),
          invalidateOrganization,
          isCurrentOrganization: (entity) =>
            baseUtil.idEquals(organizationId, entity),
          refetchOrganization,
        }}
      >
        {children}
      </OrganizationDispatchContext.Provider>
    </OrganizationContext.Provider>
  );
});
