import React from 'react';
import {
  getLotStatus,
  ProfileTile,
} from '@goodfynd/react-web.dashboard.profile-switcher';
import { stringUtil } from '@goodfynd/react-web.lib.utils';
import { StyledText } from '@goodfynd/react-web.typography.text';
import { Autocomplete } from '@goodfynd/react-web.ui.auto-complete';

import { useApp } from '../../../context/AppContext';
import { useOrganizationApi } from '../../../hooks/api';

import type {
  AutocompleteEventOrganizationsProps,
  OganizationFilterOption,
} from './types';

export function AutocompleteEventOrganizations({
  idPropertyName = 'nameId',
  ...props
}: AutocompleteEventOrganizationsProps) {
  const { user } = useApp();
  const api = useOrganizationApi();
  return (
    <Autocomplete<OganizationFilterOption>
      groupBy={(option) => option.type ?? ''}
      label="Search"
      placeholder="Find lots and organizations..."
      getOptions={async (request) => {
        try {
          const { lots, organizations } = await api.autocompleteLots(request);
          return [
            ...lots.map((result) => ({
              description: result.nameId,
              icon: 'lot',
              id: result.id,
              label: [result.organization, result.uniqueName || result.name]
                .filter(Boolean)
                .join(': '),
              type: 'lot',
              value: result[idPropertyName] || result.id,
              status: getLotStatus(result.isDeleted ?? false),
            })),
            ...(user.isRestrictedAdmin ? organizations : []).map((result) => ({
              description: 'Organization',
              icon: 'organization',
              id: result.id,
              label: result.uniqueName || result.name,
              type: 'organization',
              value: result[idPropertyName] || result.id,
              status: 'organization',
            })),
          ] as OganizationFilterOption[];
        } catch (error) {
          console.error(error);
          return [];
        }
      }}
      renderGroup={(params) => (
        <li>
          <StyledText css={{ marginLeft: 16, padding: 10 }} type="subh2">
            {stringUtil.pluralize(stringUtil.titleCase(params.group), 2)}
          </StyledText>
          <div>{params.children}</div>
        </li>
      )}
      renderOption={(props, option) => (
        <ProfileTile
          {...props}
          key={option.id}
          label={option.label}
          status={option.status}
          type={option.type as any}
        />
      )}
      {...props}
    />
  );
}
