const endpoints = {
  accounts: {
    autocomplete: 'users/v2/autocomplete',
    create: 'event-dashboard/accounts/v2',
    check: 'event-dashboard/accounts/v2/check',
    update: 'event-dashboard/accounts/v2',
    userInfo: 'event-dashboard/accounts/v2/user-info',
  },
  categories: {
    base: 'vendor-dashboard/categories/v2/:id',
    search: 'vendor-dashboard/categories/v2',
  },
  cms: {
    link: '/api/cms/link',
    retrieve: '/api/cms/retrieve',
    search: '/api/cms/search',
  },
  events: {
    autocomplete: 'event-dashboard/lots/v2/autocomplete',
    base: 'event-dashboard/events/v2/:id',
    byLot: 'event-dashboard/events/v2/lot/:id',
    cancelDate: 'event-dashboard/events/v2/:id/cancel',
    lotSearch: 'event-dashboard/events/v2/search',
    search: 'vendor-dashboard/vendor-events/v2/search',
  },
  locations: {
    byIP: 'https://ipapi.co/json',
    byIPLegacy: 'https://freegeoip.net/json',
    getCounty: 'https://geo.fcc.gov/api/census/area',
  },
  lots: {
    autocomplete: 'event-dashboard/lots/v2/autocomplete',
    base: 'event-dashboard/lots/v2/:id',
    create: 'event-dashboard/lots/v2',
    events: {
      awaitingConfirmation: 'event-dashboard/lots/v2/:id/awaiting-confirmation',
      confirmed: 'event-dashboard/lots/v2/:id/confirmed',
      interested: 'event-dashboard/lots/v2/:id/interested',
    },
    interest: {
      add: 'event-dashboard/lots/v2/:id/interest/add',
      remove: 'event-dashboard/lots/v2/:id/interest/remove',
    },
    notifyVendors: 'event-dashboard/lots/v2/:id/notify/vendors',
    visible: 'admin-dashboard/lots/v2/:id/visible/:visible',
    vendorConfirm: 'event-dashboard/lots/v2/:id/confirm',
    vendorRejection: 'event-dashboard/lots/v2/:id/reject',
    bookVendor: 'event-dashboard/lots/v2/:id/select',
  },
  orders: {
    cancel: 'orders/cancel/:id',
    complete: 'orders/complete/:id/:transactionId',
    prepare: 'orders/prepare/:id',
    ready: 'orders/ready/:id',
    search: 'event-dashboard/order-search/v2/lot',
    send: {
      receipt: 'orders/send/receipt/:id',
      sms: 'orders/send/sms/:id',
    },
    transactions: 'orders/transactions',
  },
  organizations: {
    autocomplete: 'event-dashboard/organizations/v2/autocomplete',
    autocompleteLotsAndOrganizations:
      'organization-structure/v2/lot-organization-autocomplete',
    autocompleteVendorsAndOrganizations:
      'organization-structure/v2/vendor-organization-autocomplete',
    base: 'event-dashboard/organizations/v2/:id',
    structure: 'organization-structure/v2/:id',
    territories: 'vendor-dashboard/territories/v2',
  },
  products: {
    base: 'products/:id/:includeSchedule',
    toggle: 'vendor-dashboard/products/v2/:id/visible/:visible',
  },
  profiles: {
    lot: 'v3/event-dashboard/profiles/lots/:id',
  },
  schedule: {
    lotSearch: 'event-dashboard/schedules/v2/search',
    search: 'vendor-dashboard/schedules/v2/search',
    cancel: 'vendor-dashboard/schedules/v2/:id/cancel',
  },
  user: {
    defaultLot: 'users/v2/:userId/default-lot/:lotNameId',
  },
  vendors: {
    autocomplete: 'event-dashboard/vendors/v2/autocomplete',
    base: 'event-dashboard/vendors/v2/:id',
    products: 'event-dashboard/products/v2',
  },
};

export default endpoints;
