import * as dateUtil from '@goodfynd/react-web.utils.date-util';

import config from '../../config';
import * as arrayUtil from '../array-util';
import * as apis from './google-apis';

const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = {
  0: 'https://www.googleapis.com/auth/calendar',
  1: 'https://www.googleapis.com/auth/calendar.readonly',
  2: 'https://www.googleapis.com/auth/calendar.events.readonly',
};

export const list = async ({
  readonly = true,
  options = {},
}): Promise<gapi.client.calendar.CalendarListEntry[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const signedIn = true;
      await apis.load({
        docs: DISCOVERY_DOCS,
        scopes: SCOPES[readonly ? 1 : 0],
      });
      if (signedIn) {
        const googleApiClient: any = gapi.client;
        const response = await googleApiClient.calendar.calendarList.list({
          // 'maxResults': 313,
          ...options,
        });

        resolve(response.result.items || []);
      } else {
        console.error('Sign in failed.');
        resolve([]);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const listEvents = async ({
  id = 'primary',
  options = {},
}: {
  id?: string;
  options: Record<string, unknown>;
}): Promise<gapi.client.calendar.Event[]> => {
  try {
    const minDate = new Date();
    const maxDate = dateUtil.endOfMonth(minDate);
    const response = await gapi.client.calendar.events.list({
      calendarId: id,
      // 'maxResults': 313,
      orderBy: 'startTime',
      showDeleted: false,
      singleEvents: true,
      timeMax: maxDate.toISOString(),
      timeMin: minDate.toISOString(),
      ...options,
    });
    return response.result.items || [];
  } catch (error) {
    console.debug(error);
    return [];
  }
};

export type MonthEvent = {
  date: string;
  list: MonthEventItem[];
  number: number;
};

export type MonthEventItem = {
  end: Date;
  endLabel: string;
  key: string;
  missing?: boolean;
  name?: string;
  selected: boolean;
  start: Date;
  startLabel: string;
  timeLabel: string;
} & Partial<JsonLocation>;

export const parseEvents = (
  events: gapi.client.calendar.Event[],
  savedLocations: { [key: string]: JsonLocation },
  addDays = 0
) => {
  const monthEvents: MonthEvent[] = arrayUtil.create(32).map((_day, index) => {
    const monthNumber = index;
    const date = dateUtil.addDays(
      dateUtil.setDate(new Date(), monthNumber),
      addDays
    );
    return {
      date: dateUtil.format(date, config.dateFormats.weekDayMonthDay),
      list: [],
      number: monthNumber,
    };
  });

  for (let i = events.length - 1; i >= 0; i--) {
    const entry = events[i];
    if (
      entry.end?.dateTime &&
      entry.start?.dateTime &&
      dateUtil.isFuture(entry.start.dateTime)
    ) {
      const endTime = new Date(entry.end.dateTime);
      const startTime = new Date(entry.start.dateTime);
      const endLabel = dateUtil.format(endTime, config.dateFormats.time); // 'h:mm a'
      const startLabel = dateUtil.format(startTime, config.dateFormats.time);
      const date = dateUtil.getDate(startTime);
      const { list } = monthEvents[date];
      const total = list.length;
      const key = `entry-${total}-${i}`;
      const name = entry.summary?.trim() || 'Unknown Location';
      const locationKey = name.toLowerCase();
      const location = savedLocations[locationKey];

      const info: MonthEventItem = {
        end: endTime,
        endLabel,
        key,
        name,
        selected: false,
        start: startTime,
        startLabel,
        timeLabel: startLabel + config.unicode.dashEnPadded + endLabel,
        ...(location || {
          missing: true,
        }),
        address: location
          ? location.address === name
            ? location.coordinates
            : location.address
          : undefined,
      };
      list.push(info);
      monthEvents[date].list = list;
    }
  }

  console.debug(monthEvents);
  return monthEvents.filter((date) => date.list.length);
};
