import { stringUtil } from '@goodfynd/react-web.utils.string-util';

export const getDashboardRoutePath = (
  pathTemplate: string,
  entityId = 'profile'
): string => {
  return stringUtil.replace(pathTemplate, {
    ':id': entityId,
  });
};

export const getOrganizationRoutePath = (
  pathTemplate: string,
  orgNameId: string
): string => {
  return stringUtil.replace(pathTemplate, {
    ':organizationId': orgNameId,
  });
};
