import { onSnapshot } from 'mobx-state-tree';

import config from '../../config';
import { local } from '../../utils/storage-util';
import { Environment } from '../environment';

import type { RootStore } from './index';
import { RootStoreModel } from './index';

export async function createEnvironment(): Promise<Environment> {
  const env = new Environment();
  return env;
}

let _store: RootStore;
export const useRootStore = () => async () => {
  let data: any;
  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  if (!_store) {
    try {
      // load data from storage
      data = local.get(config.storageKeys.rootState, {});
      console.debug('store-age...', data);
      // try to hydrate rootstore with snapshot
      _store = RootStoreModel.create(data, env);
    } catch (e: any) {
      // if there's any problems loading, then let's at least fallback to an empty state
      // instead of crashing.
      _store = RootStoreModel.create({}, env);

      // but please inform us what happened
      console.error(e.message, null);
    }
  }

  // track changes & save to storage
  onSnapshot(_store, (snapshot) => {
    console.debug('...SAVE', snapshot);
    local.set(config.storageKeys.rootState, snapshot);
  });

  return _store;
};
