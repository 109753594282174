import { disabledRowClassName } from '@goodfynd/react-web.styles';
import { styled } from '@goodfynd/react-web.theme';

export const StyledBody = styled('div', {
  fontSize: 16,
  display: 'block',
  height: '100%',
  minHeight: 500,
  '@mdMax': {
    paddingTop: 64,
  },
  [`& .${disabledRowClassName}`]: {
    background: '$Neutral01',
    '& img': {
      opacity: 0.5,
    },
    '& p': {
      color: '$Neutral06',
    },
  },
});
