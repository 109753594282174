/**
 * Burn it all to the ground.
 */
export function clear(): boolean {
  try {
    localStorage.clear();
    return true;
  } catch {
    return false;
  }
}

/**
 * Loads something from storage and optionally runs it through JSON.parse.
 *
 * @param key The key to fetch.
 */
export function get<T = string>(key: string, defaultValue?: T): T | undefined {
  const value = localStorage.getItem(key);
  try {
    return value ? (JSON.parse(value) as T) : defaultValue;
  } catch {
    return (value ?? defaultValue) as T;
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to remove data for.
 */
export function remove(key: string): boolean {
  try {
    localStorage.removeItem(key);
    return true;
  } catch {
    return false;
  }
}

/**
 * Saves an object or string to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function set(key: string, value: any): boolean {
  try {
    localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
    return true;
  } catch {
    return false;
  }
}
