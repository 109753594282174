import React from 'react';

import { AppContext, AppDispatchContext } from './AppContext';

export { default as AppProvider } from './AppContext';

export function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('The useApp hook must be used within a AppProvider.');
  }
  return context;
}

export function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useAppDispatch hook must be used within a AppProvider.'
    );
  }
  return context;
}
