import React from 'react';

import { EventContext, EventDispatchContext } from './EventContext';

import type { EventContextValue, EventDispatchContextValue } from './types';

export { default as EventProvider } from './EventContext';

export { EventContext, EventDispatchContext };

export function useEvent(): EventContextValue {
  const context = React.useContext(EventContext);
  if (context === undefined) {
    throw new Error('The useEvent hook must be used within a EventProvider.');
  }
  return context;
}

export function useEventDispatch(): EventDispatchContextValue {
  const context = React.useContext(EventDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useEventDispatch hook must be used within a EventProvider.'
    );
  }
  return context;
}
